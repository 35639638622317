import { render, staticRenderFns } from "./PurchaseFast.vue?vue&type=template&id=7c1a2c66&scoped=true&"
import script from "./PurchaseFast.vue?vue&type=script&lang=js&"
export * from "./PurchaseFast.vue?vue&type=script&lang=js&"
import style0 from "./PurchaseFast.vue?vue&type=style&index=0&id=7c1a2c66&prod&scoped=true&lang=css&"
import style1 from "styles/views/orderAdmin/purchase.css?vue&type=style&index=1&id=7c1a2c66&prod&scoped=true&lang=css&"
import style2 from "./PurchaseFast.vue?vue&type=style&index=2&id=7c1a2c66&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c1a2c66",
  null
  
)

/* custom blocks */
import block0 from "./PurchaseFast.vue?vue&type=custom&index=0&blockType=meta&name=viewport&content=width%3Ddevice-width%2C%20initial-scale%3D1.0%2C%20maximum-scale%3D1.0%2C%20user-scalable%3D0"
if (typeof block0 === 'function') block0(component)

export default component.exports